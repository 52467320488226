export const validateReferenceNo = (value: string) => {
  if (!value) {
    return 'Reference No. cannot be empty !'
  }
}

export const validateProgramType = (value: string) => {
  if (!value) {
    return 'Program type cannot be empty !'
  }
}
export const validateScheme = (value: string) => {
  if (!value) {
    return 'Scheme cannot be empty !'
  }
}
export const validateIncidantType = (value: number) => {
  if (!value) {
    return 'Incident type cannot be empty !'
  }
}
export const validateIncidantDate = (value: number) => {
  if (!value) {
    return 'Incident date cannot be empty !'
  }
}
export const validateApplicantName = (value: string) => {
  if (!value) {
    return 'Applicant name cannot be empty !'
  }
}
export const validateApplicantAddress = (value: string) => {
  if (!value) {
    return 'Applicant address cannot be empty !'
  }
}

export const validateDateofBirth = (value: number) => {
  if (!value) {
    return 'Date of Birth cannot be empty !'
  }
}
export const validateAge = (value: number) => {
  if (!value) {
    return 'Age cannot be empty !'
  }
}
export const validateNICnumber = (newNIC: string, OldNIC: string) => {
  if (!newNIC || !OldNIC) {
    return 'NIC number cannot be empty !'
  }
}
export const validatePassport = (value: string) => {
  if (!value) {
    return 'Passport number cannot be empty !'
  } else if (value.length > 8) {
    return 'Passport number cannot be more than 8 characters !'
  }
}
export const validatSeniorCitizenId = (value: string) => {
  if (!value) {
    return 'Senior citizen ID cannot be empty !'
  }
}
export const validateDrivingLicense = (value: string) => {
  if (!value) {
    return 'Driving license number cannot be empty !'
  }
}
export const validateTic = (value: string) => {
  if (!value) {
    return 'TIC number cannot be empty !'
  }
}
export const validateBCNumber = (value: string) => {
  if (!value) {
    return 'Birth certificate number cannot be empty !'
  }
}
export const validateProvince = (value: number | undefined) => {
  if (!value) {
    return 'Please select the province !'
  }
}
export const validateDistrict = (value: number | undefined) => {
  if (!value) {
    return 'Please select the district !'
  }
}
export const validateDsDivision = (value: number | undefined) => {
  if (!value) {
    return 'Please select the DS division !'
  }
}
export const validateDCNumber = (value: string | undefined) => {
  if (!value) {
    return 'Death certificate number cannot be empty !'
  }
}
export const validateDCNumberProvince = (value: number | undefined) => {
  if (!value) {
    return 'Please select the province'
  }
}
export const validateDCNumberDistrict = (value: number | undefined) => {
  if (!value) {
    return 'Please select the district'
  }
}
export const validateDCNumberDsDivision = (value: number | undefined) => {
  if (!value) {
    return 'Please select the DS division !'
  }
}
export const validateAggNICnumber = (newNIC: string, OldNIC: string) => {
  if (!newNIC || !OldNIC) {
    return 'Aggrieved person’s NIC cannot be empty !'
  }
}
export const validateAggBCNumber = (value: string | undefined) => {
  if (!value) {
    return 'Aggrieved person’s birth certificate number cannot be empty !'
  }
}
export const validateAggBCProvince = (value: number | undefined) => {
  if (!value) {
    return 'Please select the province'
  }
}
export const validateAggBCDistrict = (value: number | undefined) => {
  if (!value) {
    return 'Please select the district'
  }
}
export const validateAggBCDsDivision = (value: number | undefined) => {
  if (!value) {
    return 'Please select the DS division !'
  }
}

export const validateAssetIdentificationNumber = (value: string) => {
  if (!value) {
    return 'Asset identification number cannot be empty !'
  }
}
export const validateInstrumentProvince = (value: number | undefined) => {
  if (!value) {
    return 'Please select the province'
  }
}
export const validateInstrumentDistrict = (value: number | undefined) => {
  if (!value) {
    return 'Please select the district'
  }
}
export const validateInstrumentDsDivision = (value: number | undefined) => {
  if (!value) {
    return 'Please select the DS division !'
  }
}
export const validateReligiousPlaceRegNumber = (value: string) => {
  if (!value) {
    return 'Religious place registration number cannot be empty !'
  }
}

export const validateReligiousPlaceName = (value: string) => {
  if (!value) {
    return 'Religious place registration name cannot be empty !'
  }
}

export const validateGNDivision = (value: number | undefined) => {
  if (!value) {
    return 'Please select the GN division !'
  }
}
export const validateFileRecievedDate = (value: number | undefined) => {
  if (!value) {
    return 'Please select the file received date !'
  }
}
export const validateIdentificationType = (value: string) => {
  if (!value) {
    return 'Please choose the identification type !'
  }
}
export const validateAggrievedPersonsAge = (value: number, scheme: string) => {
  if (!value) {
    return 'Aggrieved person’s age cannot be empty !'
  } else if (scheme === 'GSI' && value < 16) {
    return 'Aggrieved person’s age cannot be below 16 !'
  }
}

export const validateAppliedDate = (value: number | undefined) => {
  if (!value) {
    return 'Please select the applied date !'
  }
}
